import React from "react";

import mainview from "../images/mainview.png";

export default function InterfaceMain() {
  return (
    <div>
      <h2>Affichage principal</h2>
      <p>
        Cette zone est l'<b>affichage principal</b> de Dotter.science. Elle
        affiche la page actuellement en cours d'utilisation. Elle est composée
        d'un ou plusieurs blocs symbolisés par un carré de couleur en haut à
        gauche et d'un titre.
      </p>
      <img src={mainview} alt="Vue principale" className="centered" />
    </div>
  );
}
