import React from "react";

import topbar from "../images/topbar.png";

export default function InterfaceTop() {
  return (
    <div>
      <h2>Barre supérieure</h2>
      <img src={topbar} className="centered fullwidth" alt="Barre supérieure" />
      <p>
        La <b>barre supérieure</b> est principalement une zone d'affichage. Elle
        comprends différents éléments :<li>Le titre de la page actuelle</li>
        <li>
          Un bandeau promotionnel (facultatif) : qui déroule un message lié à
          l'étude et cliquable. Ce message est configuré par les promoteurs de
          l'étude en cours.
        </li>
        <li>
          Une cloche de notification : affiche les notifications liées à
          l'étude.
        </li>
        <li>
          Un bouton d'assitance : en cas de problème, cliquez dessus pour
          contacter le support de Dotter.science.
        </li>
      </p>
    </div>
  );
}
