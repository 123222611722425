import React from "react";
import Note from "../../../components/Note";

export default function InterfaceMenu() {
  return (
    <div>
      <h2>Menu principal</h2>
      <p>
        Le <b>menu latéral</b> permet la navigation entre les différentes zones
        de Dotter.science. Il est découpé en trois parties :
        <li>
          La partie personnelle : comprenant les sous-menus liés à l'utilisateur
        </li>
        <li>
          L'encart d'étude en cours : affiche l'étude actuellement sélectionnée
          sur laquelle l'utilisateur est en train de travailler
        </li>
        <li>
          Les onglets de navigation : liste les différents menus et sous-menus
          de l'outil Dotter.science
        </li>
      </p>
      <p>
        Pour naviguer d'une page à l'autre, cliquez simplement sur l'onglet dans
        le menu latéral : l'onglet actif d'affiche avec un fond turquoise.
      </p>
      <p>Voici les différentes menus disponibles :</p>
      <div className="responsive-table">
        <table>
          <tr>
            <td>Menu</td>
            <td>Page</td>
            <td>Contenu</td>
          </tr>
          <tr>
            <td rowSpan={3}>
              Personnel
              <br />
              (nom de l'utilisateur)
            </td>
            <td>Mon profil</td>
            <td>
              Permet d'accéder aux informations personnelles de l'utilisateur et
              des paramètres de l'application Dotter.science. Voir plus sur le
              guide :{" "}
              <a href="/fr/guide/profile">
                <b>Gestion du profil</b>
              </a>
            </td>
          </tr>
          <tr>
            <td>Mes études</td>
            <td>
              Affiche la liste des études auxquelle l'utilisateur a accès et
              permet de naviguer entres elles. L'étude actuellement sélectionnée
              s'affiche dans le menu dans l'encart blanc.
            </td>
          </tr>
          <tr>
            <td>Déconnexion</td>
            <td>
              En cliquant sur cet onglet, vous êtes directement déconnecté de
              votre session Dotter.science. La page de connexion s'ouvre alors.
            </td>
          </tr>
          <tr>
            <td rowSpan={3}>Conception</td>
            <td>Administratif</td>
            <td>
              Permet de configurer les informations administratives d'une étude.
              L'accès en modification est permis uniquement aux administrateurs
              locaux.
            </td>
          </tr>
          <tr>
            <td>Schéma d'étude</td>
            <td>
              Permet de configurer la randomisation et les groupes de
              randomisation d'une étude ainsi que l'affichage des patients.
            </td>
          </tr>
          <tr>
            <td>Édition de l'étude</td>
            <td>
              Permet de construire l'eCRF d'une étude, créer des formulaires et
              des variables.
            </td>
          </tr>
          <tr>
            <td rowSpan={3}>Live</td>
            <td>Suivi des patients</td>
            <td>
              Affiche la liste des patients de l'étude. En cliquant sur un
              patient, on accède ainsi à son dossier et aux formulaires à
              compléter.
            </td>
          </tr>
          <tr>
            <td>Liste des queries</td>
            <td>
              Affiche l'ensemble des queries de l'étude. Pour plus de détails
              voir la page sur les{" "}
              <a href="/fr/advanced/queries">
                <b>Queries</b>
              </a>
              .
            </td>
          </tr>
          <tr>
            <td>Liste des événements</td>
            <td>
              Affiche l'historique des événements de l'étude. Pour plus de
              détails voir la page sur les{" "}
              <a href="/fr/advanced/events">
                <b>Événements</b>
              </a>
              .
            </td>
          </tr>
          <tr>
            <td>Analyse</td>
            <td>Exports</td>
            <td>
              Permet de demander l'export des données d'étude (en fichier .csv
              ou .xls). Pour plus de détails sur les exports voir la page sur
              les{" "}
              <a href="/fr/advanced/export">
                <b>Exports</b>
              </a>
              .
            </td>
          </tr>
        </table>
      </div>
      <Note type="tip">
        En mode tablette ou mobile, pour afficher le menu latéral, cliquez sur
        le bouton burger ( <b>☰</b> ) situé en haut à droite de l'écran.
      </Note>
    </div>
  );
}
