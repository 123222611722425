import React from "react";
import "./InterfaceInteractive.style.scss";

interface IInterfaceInteractiveProps {
  onClick: (currentarea) => void;
}

export default function InterfaceInteractive({
  onClick,
}: IInterfaceInteractiveProps) {
  function handleClick(currentarea) {
    onClick(currentarea);
  }

  return (
    <div className="interface-root">
      <div className="area-menu" onClick={() => handleClick("menu")}>
        <h3>Menu principal</h3>
      </div>
      <div className="area-top" onClick={() => handleClick("top")}>
        <h3>Barre supérieure</h3>
      </div>
      <div className="area-main" onClick={() => handleClick("main")}>
        <h3>Affichage principal</h3>
      </div>
      <div className="area-bottom" onClick={() => handleClick("bottom")}>
        <h3>Barre inférieure</h3>
      </div>
    </div>
  );
}
