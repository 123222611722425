import React from "react";
import Note from "../../../components/Note";
import bottombar from "../images/bottombar.png";

export default function InterfaceBottom() {
  return (
    <div>
      <h2>Barre inférieure</h2>
      <p>
        La <b>barre inférieure</b> est une barre d'action. Elle affiche selon le
        contexte des boutons permettant d'intéragir avec la page comme pour{" "}
        <b>Enregistrer</b>, <b>Ajouter un élément</b>,{" "}
        <b>Randomiser un patient</b>, etc...
      </p>
      <img
        src={bottombar}
        className="centered fullwidth"
        alt="Barre inférieure"
      />
      <Note type="note">
        Si un bouton est grisé cela signifie qu'il est désactivé. Il se peut
        alors qu'il manque des informations à fournir sur la page actuelle ou
        que vous n'ayez pas la permission d'effectuer l'action.
      </Note>
    </div>
  );
}
