import React, { useState } from "react";
import "../../styles/Page.scss";
import Layout from "../../components/Layout";

import { ScreenSearchDesktop } from "@mui/icons-material";

import InterfaceInteractive from "../../components/InterfaceInteractive";
import Menu from "../../content/guides/interface/Menu";
import Top from "../../content/guides/interface/Top";
import Main from "../../content/guides/interface/Main";
import Bottom from "../../content/guides/interface/Bottom";

export default function GuideInterface() {
  const [area, setArea] = useState<string>("");

  function handleClick(currentarea: string) {
    setArea(currentarea);
  }

  let xAreaGuide;
  switch (area) {
    case "menu":
      xAreaGuide = <Menu />;
      break;
    case "top":
      xAreaGuide = <Top />;
      break;
    case "main":
      xAreaGuide = <Main />;
      break;
    case "bottom":
      xAreaGuide = <Bottom />;
      break;
  }

  return (
    <Layout title="L'interface de Dotter.science">
      <div className="page-body">
        <div className="page-title guide">
          <ScreenSearchDesktop />
          <h1>L'interface de Dotter.science</h1>
        </div>
        <InterfaceInteractive onClick={handleClick} />
        <p style={{ textAlign: "center" }}>
          (Cliquez sur l'interface interactive pour accéder au détail)
        </p>
        {xAreaGuide}
      </div>
    </Layout>
  );
}
